
  .chatbox {
    width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
  }
  .messages {
    flex: 1;
    overflow-y: scroll;
    /*border: 1px solid #ccc;*/
    padding: 10px;
  }
  #input-container {
    width: 600px;
    display: flex;
    margin-top: 10px;
  }
  #input {
    flex-grow: 1;
    padding: 5px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
  }
  #send {
    padding: 5px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
  }
  .message {
    padding: 5px;
    margin-bottom: 30px;
    border-radius: 5px;
    font-size: 16px;
  }
  .user {
    background-color: #ffffff;
  }
  .bot, .assistant {
    /*background-color: #4CAF50;
    color: white;*/
    white-space: pre-wrap;
  }

  .colorBar {
    background-color: #ff0000; /* Example color */
    height: 10px; /* Adjust height as needed */
    width: 100%; /* Full width of the box */
    position: absolute;
    top: 0;
    left: 0;
  }