
  #chatbox {
    width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  #messages {
    height: 300px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 10px;
  }
  #input-container {
    width: 600px;
    display: flex;
    margin-top: 10px;
  }
  #input {
    flex-grow: 1;
    padding: 5px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
  }
  #send {
    padding: 5px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
  }
  .message {
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 16px;
  }
  .user {
    background-color: #F0F0F0;
  }
  .bot {
    background-color: #4CAF50;
    color: white;
  }
