.droparea {
    
    height: 430px;
    border: 2px dashed #ccc;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    margin: 40px
  }
  
  .droparea p {
    margin: 0;
  }
  
  .imagecontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .imagecontainer img {
    margin: 1px;
    max-width: 300px;
    max-height: 400px;
  }
  
  .droparea.dragover {
    border-color: #4caf50;
  }


  .imagewrapper {
    max-width: 640px; /* Change the maximum width as desired */
    max-height: 430px; /* Change the maximum height as desired */
    overflow: hidden;
  }
  
  .uploadedimage {
    width: auto;
    height: 100%;
  }
  
 