* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Fira Sans', sans-serif;
}

.App {
    min-height: 100vh;
    position: relative;
    background-color: #313131;
}

.camera {
    position: absolute;
}

.result {
    position: fixed;
    top: 0;
    left: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(0,0,0,0.9);
    transition: 0.4s;
}

.result.hasPhoto {
    left: 0;
}


video {
    height:80%;
    width: auto;
    max-width: 100%;
}



canvas {
    height:50%;
    width: auto;
    max-width: 100%;
    max-height: 90%
}


button {



  background-color: rgba(251, 251, 251, 1.0);
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 200;
  line-height: 10px;
  list-style: none;
  margin: 0;
  padding: 8px 10px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

